
@import "stylesheets/scout/variables";

.scroll-view {
  max-height: 600px;
  overflow-y: auto;
  max-width: 100%;
}

.custom-table {
  margin-bottom: 0;
  max-height: 80%;
  overflow-y: auto;
  table-layout: fixed;
  width: 100%;
  max-width: 100%;

  > thead > tr > th {
    padding-top: 0;
    padding-bottom: 4px;
    border-bottom: 1px solid $medium-gray;
    font-weight: 600;
  }

  > tbody > tr > td {
    margin-right: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    border-top: none;
    border-bottom: 1px solid $medium-gray;
    line-height: 20px;
  }

  > thead > tr > th:first-child, > tbody > tr > td:first-child {
    width: 18%;

    @media (min-width: $screen-sm-min) {
      width: 10%;
    }
  }

  .rank-col {
    padding-left: 0;
    text-align: center;
  }

  .item-col {
    padding-left: 8px;

    @media (min-width: $screen-sm-min) {
      padding-left: 18px;
    }

    @media (min-width: $screen-lg-min) {
      padding-left: 28px;
    }
  }

  .score-col {
    padding-right: 10px;
  }
}

.hover-underline:hover {
  color: inherit;
  text-decoration: underline;
}

.separator {
  &::before {
    content: "•";
    display: inline-block;
    margin: 0 8px;
  }
}

.review-count {
  display: block;

  @media (min-width: $screen-sm-min) {
    display: inline-block;
  }
}

.row.md-columns > [class*=col-].rating-bar-container {
  padding-right: 15px;
}
