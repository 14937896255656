
@import "stylesheets/scout/variables";

.stepper-box {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.empty-list {
  border-radius: $border-radius-large;
  background-color: $k-lighter-gray;
  color: $pill-gray;
}

.item-wrapper {
  @media (min-width: $screen-sm-min) {
    border: 1px solid $k-light-gray;
    border-radius: $border-radius-large;
  }
}

.toggle-section {
  @media (min-width: $screen-sm-min) {
    padding: 20px;
  }

  @media (min-width: $screen-md-min) {
    padding: 30px;
  }
}
