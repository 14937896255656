
@import "stylesheets/scout/variables";

.blue-accent {
  background-color: $blue-10;
  padding: 20px;
  border-radius: $border-radius-large;
  position: relative;

  @media (min-width: $screen-sm-min) {
    padding: 20px 30px;
  }

  .top-label {
    @media (min-width: $screen-sm-min) {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
    }
  }
}
