
@import "stylesheets/scout/variables";

@media (min-width: $screen-sm-min) {
  // holds scroll height during loading of new data
  .min-height-550 {
    min-height: 550px;
  }
}

.comparison-header {
  border-bottom: 1px solid $gray-7;
  font-weight: 600;
  padding-bottom: 4px;
}

.a-form .check-option.vertical {
  margin-bottom: 15px;
}
