
@import "stylesheets/scout/variables";

th.firm-detail-cell {
  position: sticky;
  top: 0;
  height: 170px;
  background-color: $white;

  &:first-child {
    background-color: $bg-color;
  }

  .firm-name-container {
    max-height: 42px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-height: 21px;
    color: $k-darker-gray;
  }

  .buttons-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
