
@import "stylesheets/scout/variables";

.svg-circle {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background: $newblue-10;

  .base-icon {
    width: 32px;
    height: 32px;
    stroke: $newblue-5;
    fill: transparent;
  }
}
